// Dashboard.js

import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  getDoc,
  doc,
} from "firebase/firestore";
import { db } from "./firebase";
import { AuthContext } from "./AuthContext";
import DashboardContent from "./DashboardContent";
import { getCurrentData } from "./mockData";

import "./App.css";
import "./Dashboard.css";

const Dashboard = () => {
  const [heuristicData, setHeuristicData] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState(0);
  const [previousPeriod, setPreviousPeriod] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [chartSize, setChartSize] = useState(50);
  const [userInfo, setUserInfo] = useState(null);
  const [pages, setPages] = useState([]);

  const { currentUser, logout, authIsReady } = useContext(AuthContext);
  const location = useLocation();

  useEffect(() => {
    if (currentUser) {
      fetchPages();
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      fetchData();
    }
  }, [currentUser, pages]); // Re-fetch data when pages change

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const data = await getCurrentData();

      if (data.length > 0) {
        // Sort data based on 'release' value numerically
        const sortedData = data.slice().sort((a, b) => {
          // Split version numbers into major and minor
          const [majorA, minorA] = a.release.split(".");
          const [majorB, minorB] = b.release.split(".");

          // Compare major version first
          const majorDiff = parseInt(majorA) - parseInt(majorB);
          if (majorDiff !== 0) return majorDiff;

          // If major versions are same, compare minor version
          return parseInt(minorA) - parseInt(minorB);
        });

        setHeuristicData(sortedData);

        let latestIndex = sortedData.length - 1;
        setSelectedPeriod(latestIndex);

        let previousIndex = latestIndex > 0 ? latestIndex - 1 : null;

        setPreviousPeriod(previousIndex);
      } else {
        // Instead of setting an error, we'll just set empty data
        setHeuristicData([]);
        setSelectedPeriod(null);
        setPreviousPeriod(null);
        console.log(
          "No data available. You can start by adding pages and data."
        );
      }
    } catch (error) {
      setError(`An error occurred while fetching data: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const fetchPages = () => {
    const q = query(collection(db, "pages"), orderBy("createdAt"));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const pagesData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPages(pagesData);
    });

    return () => unsubscribe();
  };

  const handleDataUpdate = async () => {
    const data = await getCurrentData();
    setHeuristicData(data);
    setSelectedPeriod(data.length - 1);
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      if (currentUser) {
        try {
          const userDoc = await getDoc(doc(db, "users", currentUser.uid));

          if (userDoc.exists()) {
            setUserInfo(userDoc.data());
          } else {
            console.log("No user document found");
          }
        } catch (error) {
          console.error("Error fetching user info:", error);
        }
      } else {
        console.log("No current user");
      }
    };

    fetchUserInfo();
  }, [currentUser]);

  const getPageTitle = () => {
    if (location.pathname === "/home") {
      return "Overview";
    } else if (location.pathname === "/compare") {
      return "Compare Products";
    } else if (location.pathname === "/add-page") {
      return "Add Product Page";
    } else if (location.pathname === "/add-release") {
      return "Add Release Page";
    } else if (location.pathname === "/data-entry") {
      return "Update Product Scores";
    } else if (location.pathname.startsWith("/page/")) {
      const pageId = location.pathname.split("/").pop();
      const currentPage = pages.find((page) => page.id === pageId);
      return currentPage ? currentPage.title : "Product Details";
    } else {
      return "Dashboard";
    }
  };

  const checkIsComparePage = location.pathname === "/compare";
  const checkIsNonChartPage =
    location.pathname === "/add-page" ||
    location.pathname === "/data-entry" ||
    location.pathname === "/add-release";

  if (!authIsReady) {
    return <div>Loading...</div>;
  }

  return (
    <DashboardContent
      sidebarOpen={sidebarOpen}
      setSidebarOpen={setSidebarOpen}
      isPlaying={isPlaying}
      setIsPlaying={setIsPlaying}
      selectedPeriod={selectedPeriod}
      setSelectedPeriod={setSelectedPeriod}
      heuristicData={heuristicData}
      chartSize={chartSize}
      setChartSize={setChartSize}
      pages={pages}
      handleDataUpdate={handleDataUpdate}
      loading={loading}
      error={error}
      logout={logout}
      userInfo={userInfo}
      pageTitle={getPageTitle()}
      previousPeriod={previousPeriod}
      isComparePage={checkIsComparePage}
      isNonChartPage={checkIsNonChartPage}
      setPreviousPeriod={setPreviousPeriod}
    />
  );
};

export default Dashboard;
