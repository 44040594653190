import { calculateAverage } from "./calculateAverage";

const calculatePreviousAverageScores = (data, previousData, isHomePage) => {
  // Calculate previous average scores only if on the home page
  const findPreviousDataset = (previousData, datasetLabel) => {
    if (!previousData || typeof previousData !== "object") {
      console.log("IN NULL");
      return null;
    }

    const previousDataValues = Object.values(previousData);
    if (!previousDataValues.length) {
      console.log("IN NULL 2");
      return null;
    }

    return previousDataValues.find(
      (data) => data?.datasets?.[0]?.label === datasetLabel
    );
  };

  return data.datasets.map((dataset) => {
    const datasetLabel = dataset.label;

    const previousDataset = previousData
      ? findPreviousDataset(previousData, datasetLabel)
      : null;

    return previousDataset
      ? calculateAverage(previousDataset.datasets[0])
      : null;
  });

  return [];
};

export default calculatePreviousAverageScores;
