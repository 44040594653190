// BottomControls.js

import React, { useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faPlay,
  faPause,
} from "@fortawesome/free-solid-svg-icons";
import { releases } from "./mockData";

const BottomControls = ({
  isPlaying,
  setIsPlaying,
  selectedPeriod,
  setSelectedPeriod,
  heuristicData,
  chartSize,
  setChartSize,
  isComparePage,
  isNonChartPage,
  onPeriodChange,
  selectedDataPoint, // Receive selectedDataPoint as prop
  updateSelectedDataPoint, // Receive updateSelectedDataPoint function as prop
  isChartSizerNeeded,
}) => {
  const location = useLocation();

  const handlePlayPause = useCallback(() => {
    setIsPlaying((prevIsPlaying) => {
      if (!prevIsPlaying) {
        // If starting to play, immediately move to the next period
        const newPeriod = (selectedPeriod + 1) % heuristicData.length;
        onPeriodChange(newPeriod);
      }
      return !prevIsPlaying;
    });
  }, [setIsPlaying, selectedPeriod, heuristicData.length, onPeriodChange]);

  const handlePeriodChange = (event) => {
    const newPeriod = parseInt(event.target.value, 10);
    onPeriodChange(newPeriod);
    if (isPlaying) setIsPlaying(false);
  };

  const handleStepBackward = () => {
    const newPeriod =
      selectedPeriod > 0 ? selectedPeriod - 1 : heuristicData.length - 1;
    onPeriodChange(newPeriod);
    if (selectedDataPoint) {
      updateSelectedDataPoint(newPeriod);
    }
    if (isPlaying) setIsPlaying(false);
  };

  const handleStepForward = () => {
    const newPeriod = (selectedPeriod + 1) % heuristicData.length;
    onPeriodChange(newPeriod);
    if (selectedDataPoint) {
      updateSelectedDataPoint(newPeriod);
    }
    if (isPlaying) setIsPlaying(false);
  };

  const getPeriodLabel = (period) => {
    return `Release ${releases[period]}`;
  };

  useEffect(() => {
    let intervalId;
    if (isPlaying) {
      intervalId = setInterval(() => {
        const newPeriod = (selectedPeriod + 1) % heuristicData.length;
        onPeriodChange(newPeriod);
        if (selectedDataPoint) {
          updateSelectedDataPoint(newPeriod);
        }
      }, 2000); // Change period every 2 seconds
    }
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [
    isPlaying,
    selectedPeriod,
    heuristicData.length,
    onPeriodChange,
    selectedDataPoint,
    updateSelectedDataPoint,
  ]);

  const isDetailPage = location.search.includes("view=detail");

  return (
    <div className="bottom-controls">
      {isComparePage ||
        (!isNonChartPage && isChartSizerNeeded && (
          <div className="sidebar-controls">
            <label htmlFor="chart-size">Chart Size</label>
            <div className="chart-size-control">
              <input
                type="range"
                id="chart-size"
                min="30"
                max="100"
                value={chartSize}
                onChange={(e) => setChartSize(Number(e.target.value))}
              />
              <span className="chart-size-value">{chartSize}%</span>
            </div>
          </div>
        ))}

      {!isNonChartPage && (
        <div className="controls">
          <button className="play-pause-button" onClick={handlePlayPause}>
            <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
          </button>
          <input
            type="range"
            min="0"
            max={releases.length - 1}
            value={selectedPeriod}
            onChange={handlePeriodChange}
            className="period-scrubber"
          />
          <div className="step-buttons">
            <button className="step-button" onClick={handleStepBackward}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <span className="period-label">
              {getPeriodLabel(selectedPeriod)}
            </span>
            <button className="step-button" onClick={handleStepForward}>
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default BottomControls;
