import React, { useState } from 'react';
import { collection, getDocs, setDoc, doc } from "firebase/firestore";
import { db } from "./firebase";
import { useNavigate } from 'react-router-dom';
import "./AddRelease.css"; // We'll create this CSS file later

const AddRelease = () => {
  const [newRelease, setNewRelease] = useState("");
  const navigate = useNavigate();

  const handleAddRelease = async (e) => {
    e.preventDefault();
    const sanitizedNewRelease = newRelease.trim();
    const invalidChars = /[#$\[\]]/;
    const validReleaseFormat = /^\d+(\.\d+)?$/;

    if (sanitizedNewRelease && !invalidChars.test(sanitizedNewRelease) && validReleaseFormat.test(sanitizedNewRelease)) {
      try {
        const pagesCollection = collection(db, "pages");
        const pagesSnapshot = await getDocs(pagesCollection);
        const pagesData = pagesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const newReleaseData = {};
        pagesData.forEach((page) => {
          const pageId = page.id;
          const pageTitle = page.title;
          newReleaseData[pageId] = {
            labels: [
              "Visibility of System Status",
              "Match between system and the real world",
              "User Control and Freedom",
              "Consistency and Standards",
              "Error Prevention",
              "Recognition rather than recall",
              "Flexibility and efficiency of use",
              "Aesthetic and minimalist design",
              "Help users recognize, diagnose, and recover from errors",
              "Help and documentation"
            ],
            datasets: [
              {
                label: pageTitle,
                data: new Array(10).fill(0),
                backgroundColor: page.color + '33',
                borderColor: page.color,
                fill: false,
                tension: 0.1,
              },
            ],
          };
        });

        const releaseDocRef = doc(db, "releases", sanitizedNewRelease);
        await setDoc(releaseDocRef, newReleaseData);
        
        alert("New release added successfully!");
        setNewRelease("");
        navigate('/add-release'); // Redirect to data entry page after adding
      } catch (error) {
        console.error("Error adding new release:", error);
        alert(`Error adding new release: ${error.message}`);
      }
    } else {
      alert("Invalid release number. Please avoid using ., #, $, [, or ].");
    }
  };

  return (
    <div className="add-release-container">
      <form onSubmit={handleAddRelease} className="add-release-form">
        <input
          type="text"
          value={newRelease}
          onChange={(e) => setNewRelease(e.target.value)}
          placeholder="New Release Number"
          required
        />
        <button type="submit">Add New Release</button>
      </form>
    </div>
  );
};

export default AddRelease;