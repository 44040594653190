import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { AuthProvider } from './AuthContext';
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPlay,
  faPause,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import {
  faGoogle,
  faFacebook,
  faTwitter,
  faMicrosoft,
} from "@fortawesome/free-brands-svg-icons";

library.add(
  faPlay,
  faPause,
  faEnvelope,
  faGoogle,
  faFacebook,
  faTwitter,
  faMicrosoft
);


library.add(faPlay, faPause);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>
);
