import React from 'react';
import './TestimonialsSection.css';

const TestimonialsSection = () => {
  const testimonials = [
    {
      image: '/images/user1.jpg',
      name: 'Samantha Lee',
      role: 'Project Manager at TechCorp',
      quote: 'YourAppName has transformed our workflow and boosted productivity.',
    },
    {
      image: '/images/user2.jpg',
      name: 'Michael Brown',
      role: 'CEO at Innovate LLC',
      quote: 'An indispensable tool for collaboration and project management.',
    },
    {
      image: '/images/user3.jpg',
      name: 'Emily Davis',
      role: 'Team Lead at CreateX',
      quote: 'The intuitive interface and robust features are unmatched.',
    },
  ];

  return (
    <section className="testimonials">
      <div className="container">
        <h2>What Our Users Say</h2>
        <div className="testimonials-grid">
          {testimonials.map((user, index) => (
            <div className="testimonial-card" key={index}>
              <img src={user.image} alt={`${user.name}`} />
              <h3>{user.name}</h3>
              <p className="role">{user.role}</p>
              <p className="quote">"{user.quote}"</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;
