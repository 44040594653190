import React, { useEffect } from "react";
import "./ToggleSwitch.css";

const ToggleSwitch = ({ currentPage, currentView, updateView }) => {
  // Initialize view from URL on mount
  useEffect(() => {
    const url = new URL(window.location.href);
    const viewParam = url.searchParams.get("view");
    if (viewParam) {
      updateView(viewParam);
    }
  }, [updateView]);

  const handleViewChange = (view) => {
    // Update URL with query parameter
    const url = new URL(window.location.href);
    url.searchParams.set("view", view);
    window.history.pushState({}, "", url);

    // Update view state
    updateView(view);
  };

  return (
    <div className="header-bar">
      <h1>{currentPage}</h1>
      <div className="toggleswitch">
        <button
          onClick={() => handleViewChange("overview")}
          className={`toggle-link ${
            currentView === "overview" ? "active" : ""
          }`}
        >
          Overview
        </button>
        <button
          onClick={() => handleViewChange("table")}
          className={`toggle-link ${currentView === "table" ? "active" : ""}`}
        >
          Scores Table
        </button>
        <button
          onClick={() => handleViewChange("treemap")}
          className={`toggle-link ${currentView === "treemap" ? "active" : ""}`}
        >
          Heuristic Tree
        </button>
        <button
          onClick={() => handleViewChange("scoreTable")}
          className={`toggle-link ${
            currentView === "scoreTable" ? "active" : ""
          }`}
        >
          Rubric Rating
        </button>
      </div>
      <div className="actions">
        <button className="secondary">blah</button>
        <button className="primary">Add</button>
      </div>
    </div>
  );
};

export default ToggleSwitch;
