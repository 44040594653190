import React, { useState } from "react";
import { getScoreColor } from "./utils/ScoreColor";
import "./TableView.css";

const TableView = ({
  pages,
  heuristics,
  heuristicData,
  heuristicDataPC,
  setSelectedDataPoint,
  setThirdPaneOpen,
  onDataPointClick,
  data,
}) => {
  const [selectedDataPoint, setDataPoint] = useState(null);
  const handleTableCellClick = (page, heuristic) => {
    const heuristicIndex = heuristics.indexOf(heuristic);
    const value = parseFloat(heuristicData[page.id]?.[heuristic][0]);

    if (!isNaN(value)) {
      const notes = heuristicData[page.id][heuristic][1] || "";
      const links = heuristicData[page.id][heuristic][2] || [];
      
      const dataPoint = {
        label: heuristic,
        value,
        // Normalize notes and links structure
        notes: {
          [heuristicIndex]: notes
        },
        links: {
          [heuristicIndex]: Array.isArray(links) ? links : [links].filter(Boolean)
        },
        pageLabel: page.title,
        color: getScoreColor(value),
        id: page.id,
        index: heuristicIndex
      };
      handleSetDataPoint(dataPoint);
      onDataPointClick(dataPoint, heuristicDataPC);
    }
  };
  // Set the selected data point in both local and passed state
  const handleSetDataPoint = (dataPoint) => {
    setDataPoint(dataPoint);
    setSelectedDataPoint(dataPoint);
    setThirdPaneOpen(true);
  };
  return (
    <div className="heuristic-table-container">
      <table className="heuristic-table">
        <thead>
          <tr>
            <th>Product Area</th>
            {heuristics.map((heuristic) => (
              <th key={heuristic}>{heuristic}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {pages.map((page) => (
            <tr key={page.id}>
              <td>{page.title}</td>
              {heuristics.map((heuristic) => (
                <td
                  key={`${page.id}-${heuristic}`}
                  style={{
                    backgroundColor: getScoreColor(
                      parseFloat(heuristicData[page.id]?.[heuristic])
                    ),
                  }}
                  onClick={() => handleTableCellClick(page, heuristic)}
                >
                  {typeof heuristicData[page.id]?.[heuristic] === "number"
                    ? heuristicData[page.id][heuristic][0].toFixed(1)
                    : heuristicData[page.id]?.[heuristic][0] || "-"}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableView;
