import React from "react";
import "./FeaturesSection.css";

const FeaturesSection = () => {
  const features = [
    {
      icon: "/icons/feature1.png",
      title: "Easy Collaboration",
      description: "Work together in real-time, no matter where you are.",
    },
    {
      icon: "/icons/feature2.png",
      title: "Customizable Workflows",
      description: "Adapt the platform to suit your unique needs.",
    },
    {
      icon: "/icons/feature3.png",
      title: "Detailed Analytics",
      description: "Gain insights with comprehensive data analysis.",
    },
  ];

  return (
    <section className="features">
      <div className="container">
        <h2>
          Enterprise level usability scoring evelautions that you can trust
        </h2>
        <div className="features-grid">
          {features.map((feature, index) => (
            <div className="feature-card" key={index}>
              <img src={feature.icon} alt={`${feature.title} icon`} />
              <h3>{feature.title}</h3>
              <p>{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
