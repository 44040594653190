import React from "react";
import { ResponsiveTreeMapHtml } from "@nivo/treemap";
import { calculateAverage } from "./utils/calculateAverage";
import { getScoreColor } from "./utils/ScoreColor";

const TreeMapView = ({
  data,
  pages,
  heuristicData,
  previousData,
  selectedPeriod,
  onDataPointClick,
  setSelectedDataPoint,
  setThirdPaneOpen,
}) => {
  // Prepare the data for the treemap
  const treemapData = {
    children: pages
      .map((page, index) => {
        const pageData = heuristicData[page.id];

        const averageScore = pageData
          ? calculateAverage({ data: Object.values(pageData) })
          : 0;

        const previousPageData = previousData?.[page.id]?.datasets?.[0];
        const previousAverageScore = previousPageData
          ? calculateAverage(previousPageData)
          : 0;

        const percentChange =
          ((averageScore - previousAverageScore) / previousAverageScore) * 100;

        // Update selectedDataPoint if this is the currently selected page

        // setSelectedDataPoint({
        //   value: parseFloat(averageScore),
        // });

        return {
          name: `${page.title}`,
          value: parseFloat(averageScore),
          color: getScoreColor(parseFloat(averageScore)),
          notes: data?.datasets[index]?.notes || {},
          links: data?.datasets[index]?.links || {},
          pageId: page.id,
          averageScore,
          previousAverageScore,
          percentChange:
            (percentChange > 0 ? "+" : "") + percentChange.toFixed(2) + "%",
        };
      })
      .sort((a, b) => b.value - a.value),
  };

  const handleNodeClick = (node) => {
    const pageData = heuristicData[node.data.pageId];
    
    if (pageData) {
      const dataPoint = {
        label: node.data.name,
        value: node.data.value,
        pageLabel: node.data.name,
        color: node.data.color,
        id: node.data.pageId,
        notes: node.data.notes,
        links: node.data.notes,
        scorePage: true,
      };
      setSelectedDataPoint(dataPoint);
      setThirdPaneOpen(true);
      if (onDataPointClick) {
        onDataPointClick(dataPoint);
      }
    }
  };

  return (
    <div className="heuristic-treemap-container">
      <ResponsiveTreeMapHtml
        data={treemapData}
        identity="name"
        value="value"
        valueFormat=".2f"
        margin={{ top: 0, right: 0, bottom: 10, left: 10 }}
        label={(e) =>
          `${e.data.name} ${e.data.value} (${e.data?.percentChange})`
        }
        innerPadding={15}
        outerPadding={15}
        borderWidth={0}
        enableParentLabel={false}
        labelTextColor="#000000"
        nodeOpacity={1}
        borderRadius={20}
        motionConfig="gentle"
        colors={({ data }) => `${data.color}`}
        style={{
          borderRadius: "10px",
        }}
        onClick={handleNodeClick}
      />
    </div>
  );
};

export default TreeMapView;
