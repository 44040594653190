import { db } from "./firebase";
import { collection, getDocs, setDoc, doc, addDoc } from "firebase/firestore";

export const heuristics = [
  {
    label: "Visibility of System Status",
    description:
      "Keep users informed about what is happening through appropriate feedback within a reasonable time.",
  },
  {
    label: "Match between system and the real world",
    description:
      "Speak the user's language, with words, phrases, and concepts familiar to the user, rather than system-oriented terms.",
  },
  {
    label: "User Control and Freedom",
    description:
      "Provide users with easy ways to undo actions, exit unexpected states, and navigate freely through the interface.",
  },
  {
    label: "Consistency and Standards",
    description:
      "Follow platform conventions and consistent design patterns throughout the interface to avoid confusing users.",
  },
  {
    label: "Error Prevention",
    description:
      "Design the interface to minimize the occurrence of errors through clear instructions, constraints, and confirmations.",
  },
  {
    label: "Recognition rather than recall",
    description:
      "Make objects, actions, and options visible and easily retrievable to minimize the user's memory load.",
  },
  {
    label: "Flexibility and efficiency of use",
    description:
      "Accommodate both novice and expert users by providing shortcuts, accelerators, and customizable options.",
  },
  {
    label: "Aesthetic and minimalist design",
    description:
      "Only present essential information and elements, ensuring that every extra unit of information in the interface competes with the relevant units of information and diminishes their relative visibility.",
  },
  {
    label: "Help users recognize, diagnose, and recover from errors",
    description:
      "Provide clear, constructive error messages that suggest a solution rather than just pointing out a problem.",
  },
  {
    label: "Help and documentation",
    description:
      "Offer users easily accessible and searchable help documentation to assist in understanding the interface and resolving issues.",
  },
];

export let pageColors = {};

export const fetchPageColors = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, "pages"));
    pageColors = {};
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      if (data.datasets && data.datasets[0] && data.datasets[0].label) {
        pageColors[data.datasets[0].label] = data.datasets[0].borderColor;
      }
    });
  } catch (error) {
    console.error("Error fetching page colors:", error);
  }
};

// Call this function when your app initializes
fetchPageColors();

// export let releases = ["19.2", "19.5", "19.8", "20.2"];

export let releases = [];

const fetchReleases = async () => {
  try {
    const releasesCollection = collection(db, "releases");
    const snapshot = await getDocs(releasesCollection);
    const releasesList = snapshot.docs.map((doc) => doc.id);
    releasesList.sort((a, b) => {
      const [aMajor, aMinor] = a.split(".").map(Number);
      const [bMajor, bMinor] = b.split(".").map(Number);
      return aMajor - bMajor || aMinor - bMinor;
    });
    releases = releasesList;
  } catch (error) {
    console.error("Error fetching releases:", error);
  }
};

fetchReleases();

const generatePageData = (pageName) => {
  return {
    labels: heuristics.map((h) => h.label),
    datasets: [
      {
        label: pageName,
        data: new Array(10).fill(0),
        borderColor: pageColors[pageName],
        backgroundColor: `${pageColors[pageName]}33`,
      },
    ],
  };
};

const generateHomeData = (releaseData) => {
  const pages = Object.keys(pageColors).filter((page) => page !== "Compare");
  return {
    labels: heuristics.map((h) => h.label),
    datasets: pages.map((pageName) => {
      const pageData = releaseData[pageName];
      if (pageData && pageData.datasets && pageData.datasets[0]) {
        return {
          label: pageName,
          data: pageData.datasets[0].data,
          borderColor: pageColors[pageName],
          backgroundColor: `${pageColors[pageName]}33`,
          fill: false,
          tension: 0.1,
        };
      } else {
        console.warn(`Missing or invalid data for page: ${pageName}`);
        return {
          label: pageName,
          data: new Array(heuristics.length).fill(0),
          borderColor: pageColors[pageName],
          backgroundColor: `${pageColors[pageName]}33`,
          fill: false,
          tension: 0.1,
        };
      }
    }),
  };
};

export const getCurrentData = async () => {
  try {
    const releasesCollection = collection(db, "releases");
    const snapshot = await getDocs(releasesCollection);

    if (!snapshot.empty) {
      const formattedData = snapshot.docs.map((doc) => {
        const data = doc.data();

        const releaseData = {
          release: doc.id,
          ...data,
        };

        // // Ensure all required pages exist with valid data
        // Object.keys(data).forEach((pageName) => {
        //   if (
        //     pageName !== "Compare" &&
        //     (!releaseData[pageName] || !releaseData[pageName].datasets)
        //   ) {
        //     console.warn(
        //       `Missing or invalid data for ${pageName} in release ${doc.id}. Initializing with default values.`
        //     );
        //     releaseData[pageName] = {
        //       labels: heuristics.map((h) => h.label),
        //       datasets: [
        //         {
        //           label: pageName,
        //           data: new Array(heuristics.length).fill(0),
        //         },
        //       ],
        //     };
        //   }
        // });

        releaseData.Home = generateHomeData(releaseData);
        return releaseData;
      });

      return formattedData;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching data from Firestore:", error);
    throw error;
  }
};

export const updateReleaseData = async (releaseNumber, releaseData) => {
  try {
    const releaseRef = doc(db, "releases", releaseNumber);
    const updatedData = { ...releaseData };
    delete updatedData.Home; // Remove Home data before saving
    await setDoc(releaseRef, updatedData, { merge: true });
  } catch (error) {
    console.error("Error updating release data:", error);
  }
};

export const addRelease = async (newRelease) => {
  try {
    releases.push(newRelease);
    const newReleaseData = {};
    Object.keys(pageColors).forEach((pageName) => {
      if (pageName !== "Compare") {
        newReleaseData[pageName] = generatePageData(pageName);
      }
    });
    const releasesCollection = collection(db, "releases");
    await setDoc(doc(releasesCollection, newRelease), newReleaseData);
  } catch (error) {
    console.error("Error adding new release:", error);
  }
};
