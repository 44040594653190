import React, { useState, useEffect, useCallback, useRef } from "react";
import { heuristics } from "./mockData";
import "./DataEntryForm.css";
import { db } from "./firebase";
import {
  collection,
  getDocs,
  setDoc,
  doc,
  deleteDoc,
  writeBatch,
  onSnapshot,
  updateDoc,
  deleteField,
} from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faChevronDown,
  faChevronUp,
  faPlus,
  faCog,
  faCalculator,
} from "@fortawesome/free-solid-svg-icons";

const DataEntryForm = ({ onDataUpdate = () => {} }) => {
  const [formData, setFormData] = useState({});
  const [linkInputs, setLinkInputs] = useState({});
  const [selectedRelease, setSelectedRelease] = useState("");
  const [selectedPage, setSelectedPage] = useState("");
  const [newRelease, setNewRelease] = useState("");
  const [pages, setPages] = useState([]);
  const [releasesList, setReleasesList] = useState([]);
  const [isReleaseFlyoutOpen, setIsReleaseFlyoutOpen] = useState(false);
  const [isPageFlyoutOpen, setIsPageFlyoutOpen] = useState(false);
  const [notes, setNotes] = useState({});
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isCalculating, setIsCalculating] = useState(false);
  const [firestoreData, setFirestoreData] = useState({});
  const [modifiedFields, setModifiedFields] = useState({});
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const isMergingRef = useRef(false);

  // Fetch dynamic pages from Firestore using real-time listener
  useEffect(() => {
    const pagesCollection = collection(db, "pages");
    const unsubscribePages = onSnapshot(pagesCollection, (snapshot) => {
      const pagesData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setPages(pagesData);
      if (pagesData.length > 0 && !selectedPage) {
        setSelectedPage(pagesData[0].id);
      }
    });

    return () => {
      unsubscribePages();
    };
  }, [selectedPage]);

  // Load data from Firestore using real-time listener
  useEffect(() => {
    const releasesCollection = collection(db, "releases");
    const unsubscribeReleases = onSnapshot(releasesCollection, (snapshot) => {
      const loadedData = {};
      const loadedNotes = {};
      const loadedLinks = {};

      snapshot.forEach((doc) => {
        const releaseData = doc.data();
        const release = doc.id;
        loadedData[release] = {};

        // Iterate through pages
        Object.entries(releaseData).forEach(([pageId, pageData]) => {
          if (pageData.datasets?.[0]?.data) {
            // Retain the entire pageData including datasets with colors
            loadedData[release][pageId] = pageData;

            // Load notes
            if (pageData.notes) {
              Object.entries(pageData.notes).forEach(([index, note]) => {
                const noteKey = `${release}-${pageId}-${index}`;
                loadedNotes[noteKey] = note;
              });
            }

            // Load links
            if (pageData.links) {
              if (!loadedLinks[pageId]) loadedLinks[pageId] = {};
              if (!loadedLinks[pageId][release])
                loadedLinks[pageId][release] = {};
              Object.entries(pageData.links).forEach(([index, links]) => {
                if (links && links.length > 0) {
                  loadedLinks[pageId][release][index] = links;
                }
              });
            }
          }
        });
      });

      setFirestoreData(loadedData); // Update latest data from Firestore

      // Merge Firestore data with local state without overwriting modified fields
      if (!isMergingRef.current) {
        isMergingRef.current = true;
        setFormData((prevFormData) => {
          return mergeFirestoreData(prevFormData, loadedData, modifiedFields);
        });
        // Use the new merge functions for notes and links
        setNotes((prevNotes) => {
          return mergeNotes(prevNotes, loadedNotes, modifiedFields);
        });
        setLinkInputs((prevLinks) => {
          return mergeLinkInputs(prevLinks, loadedLinks, modifiedFields);
        });
        isMergingRef.current = false;
      }

      // Update releasesList and selectedRelease
      const releaseKeys = Object.keys(loadedData);
      setReleasesList(releaseKeys);
      if (releaseKeys.length > 0 && !selectedRelease) {
        setSelectedRelease(releaseKeys[0]);
      }
    });

    return () => {
      unsubscribeReleases();
    };
  }, [selectedRelease, modifiedFields]);

  // Function to merge Firestore data with local state without overwriting modified fields
  const mergeFirestoreData = (
    localData,
    firestoreData,
    modifiedFields,
    isNotesOrLinks = false
  ) => {
    const mergedData = { ...localData };

    Object.keys(firestoreData).forEach((release) => {
      Object.keys(firestoreData[release] || {}).forEach((pageId) => {
        if (!mergedData[release]) mergedData[release] = {};
        if (!modifiedFields[release]?.[pageId]) {
          mergedData[release][pageId] = firestoreData[release][pageId];
        } else {
          const modifiedHeuristics = modifiedFields[release][pageId].data || {};
          const existingPageData = mergedData[release][pageId] || {
            datasets: [{ data: [] }],
          };

          // Retain existing datasets and only update unmodified data points
          const mergedDatasets = existingPageData.datasets.map(
            (dataset, idx) => {
              const firestoreDataset =
                firestoreData[release][pageId].datasets[idx];
              const data = [...dataset.data];
              firestoreDataset.data.forEach((value, index) => {
                if (!modifiedHeuristics[index]) {
                  data[index] = value;
                }
              });
              return {
                ...dataset,
                ...firestoreDataset,
                data,
              };
            }
          );

          mergedData[release][pageId] = {
            ...existingPageData,
            ...firestoreData[release][pageId],
            datasets: mergedDatasets,
          };
        }
      });
    });

    return mergedData;
  };

  // New merge function for notes
  const mergeNotes = (prevNotes, loadedNotes, modifiedFields) => {
    const mergedNotes = { ...prevNotes };
    Object.keys(loadedNotes).forEach((noteKey) => {
      const [release, pageId, heuristicIndex] = noteKey.split("-");
      if (!modifiedFields[release]?.[pageId]?.notes?.[heuristicIndex]) {
        mergedNotes[noteKey] = loadedNotes[noteKey];
      }
    });
    return mergedNotes;
  };

  // New merge function for linkInputs
  const mergeLinkInputs = (prevLinks, loadedLinks, modifiedFields) => {
    const mergedLinks = { ...prevLinks };

    Object.keys(loadedLinks).forEach((pageId) => {
      if (!mergedLinks[pageId]) mergedLinks[pageId] = {};
      Object.keys(loadedLinks[pageId]).forEach((release) => {
        if (!mergedLinks[pageId][release]) mergedLinks[pageId][release] = {};
        Object.keys(loadedLinks[pageId][release]).forEach((heuristicIndex) => {
          if (!modifiedFields[release]?.[pageId]?.links?.[heuristicIndex]) {
            mergedLinks[pageId][release][heuristicIndex] =
              loadedLinks[pageId][release][heuristicIndex];
          }
        });
      });
    });

    return mergedLinks;
  };

  // Handle clicks outside of flyouts and settings
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".settings-container")) {
        setIsSettingsOpen(false);
      }
      if (!event.target.closest(".flyout-container")) {
        setIsReleaseFlyoutOpen(false);
        setIsPageFlyoutOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Handle input changes in the form
  const handleInputChange = (
    release,
    pageId,
    heuristicIndex,
    value,
    isNote = false
  ) => {
    setUnsavedChanges(true);

    // Update modifiedFields
    setModifiedFields((prev) => {
      const newModified = { ...prev };
      if (!newModified[release]) newModified[release] = {};
      if (!newModified[release][pageId]) newModified[release][pageId] = {};
      if (isNote) {
        if (!newModified[release][pageId].notes)
          newModified[release][pageId].notes = {};
        newModified[release][pageId].notes[heuristicIndex] = true;
      } else {
        if (!newModified[release][pageId].data)
          newModified[release][pageId].data = {};
        newModified[release][pageId].data[heuristicIndex] = true;
      }
      return newModified;
    });

    if (isNote) {
      setNotes((prevNotes) => ({
        ...prevNotes,
        [`${release}-${pageId}-${heuristicIndex}`]: value,
      }));
    } else {
      setFormData((prevData) => {
        const releaseData = prevData[release] || {};
        const pageData = releaseData[pageId] || {
          labels: heuristics.map((h) => h.label),
          datasets: [
            {
              data: new Array(heuristics.length).fill(0),
              borderColor:
                pages.find((p) => p.id === pageId)?.color || "#000000",
              backgroundColor:
                (pages.find((p) => p.id === pageId)?.color || "#000000") + "33",
              label: pages.find((p) => p.id === pageId)?.title || "Page",
            },
          ],
        };

        const updatedData = [...pageData.datasets[0].data];
        updatedData[heuristicIndex] = Number(value);

        return {
          ...prevData,
          [release]: {
            ...releaseData,
            [pageId]: {
              ...pageData,
              datasets: [
                {
                  ...pageData.datasets[0],
                  data: updatedData,
                },
              ],
            },
          },
        };
      });
    }
  };

  // Function to compare and get differences between two objects
  const getDifferences = (
    oldData,
    newData,
    oldNotes,
    newNotes,
    oldLinks,
    newLinks
  ) => {
    const differences = {};

    const allReleases = new Set([
      ...Object.keys(newData),
      ...Object.keys(oldData),
    ]);

    allReleases.forEach((release) => {
      const oldReleaseData = oldData[release] || {};
      const newReleaseData = newData[release] || {};
      const allPages = new Set([
        ...Object.keys(newReleaseData),
        ...Object.keys(oldReleaseData),
      ]);

      allPages.forEach((pageId) => {
        const oldPageData = oldReleaseData[pageId]?.datasets?.[0]?.data || [];
        const newPageData = newReleaseData[pageId]?.datasets?.[0]?.data || [];

        const hasDataChanged =
          JSON.stringify(oldPageData) !== JSON.stringify(newPageData);

        // Compare notes
        const oldNotesForPage = {};
        const newNotesForPage = {};
        heuristics.forEach((_, index) => {
          const noteKey = `${release}-${pageId}-${index}`;
          if (oldNotes[noteKey]) {
            oldNotesForPage[index] = oldNotes[noteKey];
          }
          if (newNotes[noteKey]) {
            newNotesForPage[index] = newNotes[noteKey];
          }
        });
        const hasNotesChanged =
          JSON.stringify(oldNotesForPage) !== JSON.stringify(newNotesForPage);

        // Compare links
        const oldLinksForPage = oldLinks[pageId]?.[release] || {};
        const newLinksForPage = newLinks[pageId]?.[release] || {};
        const hasLinksChanged =
          JSON.stringify(oldLinksForPage) !== JSON.stringify(newLinksForPage);

        if (hasDataChanged || hasNotesChanged || hasLinksChanged) {
          if (!differences[release]) differences[release] = {};
          differences[release][pageId] = {
            dataChanged: hasDataChanged ? newReleaseData[pageId] : undefined,
            notesChanged: hasNotesChanged ? newNotesForPage : undefined,
            linksChanged: hasLinksChanged ? newLinksForPage : undefined,
          };
        }
      });
    });

    return differences;
  };

  // Submit data to Firestore
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const batch = writeBatch(db);
      const differences = getDifferences(
        firestoreData,
        formData,
        {}, // Assuming we don't have oldNotes stored separately
        notes,
        {}, // Assuming we don't have oldLinks stored separately
        linkInputs
      );

      Object.keys(differences).forEach((release) => {
        const releaseRef = doc(db, "releases", release);
        const updateData = {};

        Object.keys(differences[release]).forEach((pageId) => {
          const diff = differences[release][pageId];

          const page = pages.find((p) => p.id === pageId);
          if (!page) return;

          updateData[pageId] = {};

          if (diff.dataChanged) {
            // Retain existing datasets properties
            const existingPageData = firestoreData[release]?.[pageId] || {};
            const existingDatasets = existingPageData.datasets || [];
            const existingDataset = existingDatasets[0] || {};

            updateData[pageId] = {
              ...existingPageData,
              labels: heuristics.map((h) => h.label),
              datasets: [
                {
                  ...existingDataset,
                  label: page.title,
                  data: diff.dataChanged.datasets[0].data,
                  borderColor: existingDataset.borderColor || page.color,
                  backgroundColor:
                    existingDataset.backgroundColor || page.color + "33",
                },
              ],
            };
          }

          if (diff.notesChanged) {
            updateData[pageId].notes = diff.notesChanged;
          }

          if (diff.linksChanged) {
            updateData[pageId].links = diff.linksChanged;
          }
        });

        batch.set(releaseRef, updateData, { merge: true });
      });

      await batch.commit();
      alert("Data saved successfully!");
      setUnsavedChanges(false);
      setModifiedFields({});
      onDataUpdate();
    } catch (error) {
      console.error("Error saving data:", error);
      alert("Error saving data");
    }
  };

  // Adds a new release to Firestore
  const handleAddRelease = async (e) => {
    e.preventDefault();
    const sanitizedNewRelease = newRelease.trim();
    const invalidChars = /[./#$\[\]]/;

    if (sanitizedNewRelease && !releasesList.includes(sanitizedNewRelease)) {
      if (invalidChars.test(sanitizedNewRelease)) {
        alert("Release number contains invalid characters.");
        return;
      }

      // Create initial data for the new release
      const newReleaseData = {};
      pages.forEach((page) => {
        const pageId = page.id;
        const pageTitle = page.title;
        newReleaseData[pageId] = {
          labels: heuristics.map((h) => h.label),
          datasets: [
            {
              label: pageTitle,
              data: new Array(heuristics.length).fill(0),
              borderColor: page.color,
              backgroundColor: page.color + "33",
              fill: false,
              tension: 0.1,
            },
          ],
        };
      });
      try {
        // Save the new release to Firestore
        const releaseDocRef = doc(db, "releases", sanitizedNewRelease);
        await setDoc(releaseDocRef, newReleaseData);
        setFormData((prevData) => ({
          ...prevData,
          [sanitizedNewRelease]: newReleaseData,
        }));
        setSelectedRelease(sanitizedNewRelease);
        setReleasesList((prevList) => [...prevList, sanitizedNewRelease]);
        setNewRelease("");
        onDataUpdate();
      } catch (error) {
        console.error("Error adding new release:", error);
        alert(`Error adding new release: ${error.message}`);
      }
    } else {
      alert("Invalid or duplicate release number");
    }
  };

  // Deletes a release from Firestore
  const handleDeleteRelease = async (releaseId) => {
    if (
      window.confirm(`Are you sure you want to delete release ${releaseId}?`)
    ) {
      try {
        const releaseDocRef = doc(db, "releases", releaseId);
        await deleteDoc(releaseDocRef);
        setFormData((prevData) => {
          const newData = { ...prevData };
          delete newData[releaseId];
          return newData;
        });
        setReleasesList((prevList) =>
          prevList.filter((release) => release !== releaseId)
        );
        if (selectedRelease === releaseId) {
          setSelectedRelease(releasesList.length > 1 ? releasesList[0] : "");
        }
        onDataUpdate();
        alert(`Release ${releaseId} deleted successfully.`);
      } catch (error) {
        console.error("Error deleting release:", error);
        alert(`Error deleting release: ${error.message}`);
      }
    }
  };

  // Generates random data for the selected release and page
  const generateRandomData = useCallback(
    (e) => {
      e.preventDefault(); // Prevent form submission
      setUnsavedChanges(true);
      setModifiedFields((prev) => {
        const newModified = { ...prev };
        if (!newModified[selectedRelease]) newModified[selectedRelease] = {};
        if (!newModified[selectedRelease][selectedPage])
          newModified[selectedRelease][selectedPage] = { data: {} };
        heuristics.forEach((_, index) => {
          newModified[selectedRelease][selectedPage].data[index] = true;
        });
        return newModified;
      });
      setFormData((prevData) => {
        const newData = { ...prevData };
        if (
          newData[selectedRelease] &&
          newData[selectedRelease][selectedPage]
        ) {
          let prevValue = Math.random() * 5; // Initial random value
          const updatedData = newData[selectedRelease][
            selectedPage
          ].datasets[0].data.map(() => {
            const newValue = Math.max(
              0,
              Math.min(5, prevValue + (Math.random() * 2 - 1))
            );
            prevValue = newValue;
            return Number(newValue.toFixed(1));
          });
          newData[selectedRelease][selectedPage].datasets[0].data = updatedData;
        }
        return newData;
      });
    },
    [selectedRelease, selectedPage]
  );

  // Generates random data for all releases for the selected page
  const generateRandomDataForAllReleases = useCallback(() => {
    if (!selectedPage) {
      alert("Please select a page first.");
      return;
    }
    setUnsavedChanges(true);
    setModifiedFields((prev) => {
      const newModified = { ...prev };
      releasesList.forEach((release) => {
        if (!newModified[release]) newModified[release] = {};
        if (!newModified[release][selectedPage])
          newModified[release][selectedPage] = { data: {} };
        heuristics.forEach((_, index) => {
          newModified[release][selectedPage].data[index] = true;
        });
      });
      return newModified;
    });
    setFormData((prevData) => {
      const newData = { ...prevData };
      releasesList.forEach((release) => {
        if (!newData[release]) {
          newData[release] = {};
        }
        if (!newData[release][selectedPage]) {
          newData[release][selectedPage] = {
            datasets: [{ data: new Array(heuristics.length).fill(0) }],
          };
        }

        let prevValue = Math.random() * 5; // Initial random value

        const updatedData = newData[release][selectedPage].datasets[0].data.map(
          () => {
            const newValue = Math.max(
              0,
              Math.min(5, prevValue + (Math.random() * 2 - 1))
            );
            prevValue = newValue;
            return Number(newValue.toFixed(1));
          }
        );

        newData[release][selectedPage].datasets[0].data = updatedData;
      });
      return newData;
    });
  }, [selectedPage, releasesList]);

  // Generates random data for all releases and all pages
  const generateRandomDataForEverything = useCallback(() => {
    if (
      window.confirm(
        "Are you sure you want to generate random data for all releases and all pages?"
      )
    ) {
      setUnsavedChanges(true);
      setModifiedFields((prev) => {
        const newModified = { ...prev };
        releasesList.forEach((release) => {
          if (!newModified[release]) newModified[release] = {};
          pages.forEach((page) => {
            if (!newModified[release][page.id])
              newModified[release][page.id] = { data: {} };
            heuristics.forEach((_, index) => {
              newModified[release][page.id].data[index] = true;
            });
          });
        });
        return newModified;
      });
      setFormData((prevData) => {
        const newData = { ...prevData };

        releasesList.forEach((release) => {
          if (!newData[release]) {
            newData[release] = {};
          }

          pages.forEach((page) => {
            if (!newData[release][page.id]) {
              newData[release][page.id] = {
                datasets: [{ data: new Array(heuristics.length).fill(0) }],
              };
            }

            let prevValue = Math.random() * 5; // Initial random value

            const updatedData = newData[release][page.id].datasets[0].data.map(
              () => {
                const newValue = Math.max(
                  0,
                  Math.min(5, prevValue + (Math.random() * 2 - 1))
                );
                prevValue = newValue;
                return Number(newValue.toFixed(1));
              }
            );

            newData[release][page.id].datasets[0].data = updatedData;
          });
        });

        return newData;
      });
    }
  }, [releasesList, pages]);

  // Helper function to organize pages into a hierarchical structure
  const organizePages = (pages) => {
    const pageMap = new Map();
    const rootPages = [];

    pages.forEach((page) => {
      pageMap.set(page.id, { ...page, children: [] });
    });

    pages.forEach((page) => {
      if (page.parentPage) {
        const parentPage = pageMap.get(page.parentPage);
        if (parentPage) {
          parentPage.children.push(pageMap.get(page.id));
        } else {
          rootPages.push(pageMap.get(page.id));
        }
      } else {
        rootPages.push(pageMap.get(page.id));
      }
    });

    return rootPages;
  };

  // Recursive function to render nested pages
  const renderNestedPages = (pages, level = 0) => {
    return pages.map((page) => (
      <React.Fragment key={page.id}>
        <div className={`flyout-menu-item ${level > 0 ? "nested-item" : ""}`}>
          <span
            onClick={() => {
              setSelectedPage(page.id);
              setIsPageFlyoutOpen(false);
            }}
          >
            {page.title}
          </span>
          <FontAwesomeIcon
            icon={faTrash}
            className="delete-page-icon"
            onClick={() => handleDeletePage(page.id, page.title)}
          />
        </div>
        {page.children &&
          page.children.length > 0 &&
          renderNestedPages(page.children, level + 1)}
      </React.Fragment>
    ));
  };

  // Deletes a page and its data from Firestore
  const handleDeletePage = async (pageId, pageTitle) => {
    const isConfirmed = window.confirm(
      `Are you sure you want to delete the page "${pageTitle}"? This action cannot be undone.`
    );

    if (!isConfirmed) {
      return;
    }

    try {
      const pageRef = doc(db, "pages", pageId);
      await deleteDoc(pageRef);

      const releasesSnapshot = await getDocs(collection(db, "releases"));

      for (const releaseDoc of releasesSnapshot.docs) {
        const releaseData = releaseDoc.data();

        if (releaseData[pageId]) {
          const releaseRef = doc(db, "releases", releaseDoc.id);
          await updateDoc(releaseRef, {
            [pageId]: deleteField(),
          });
        }
      }

      setPages((prevPages) => prevPages.filter((page) => page.id !== pageId));
      setFormData((prevData) => {
        const newData = { ...prevData };
        Object.keys(newData).forEach((release) => {
          if (newData[release][pageId]) {
            delete newData[release][pageId];
          }
        });
        return newData;
      });

      alert(`Page "${pageTitle}" and its data have been deleted.`);
    } catch (error) {
      console.error("Error deleting page and its data from releases:", error);
      alert("An error occurred while deleting the page. Please try again.");
    }
  };

  // Adds a link input
  const handleAddLink = (pageId, release, heuristicIndex) => {
    setUnsavedChanges(true);
    setModifiedFields((prev) => {
      const newModified = { ...prev };
      if (!newModified[release]) newModified[release] = {};
      if (!newModified[release][pageId]) newModified[release][pageId] = {};
      if (!newModified[release][pageId].links)
        newModified[release][pageId].links = {};
      newModified[release][pageId].links[heuristicIndex] = true;
      return newModified;
    });
    setLinkInputs((prev) => {
      const newInputs = { ...prev };
      if (!newInputs[pageId]) newInputs[pageId] = {};
      if (!newInputs[pageId][release]) newInputs[pageId][release] = {};
      if (!newInputs[pageId][release][heuristicIndex]) {
        newInputs[pageId][release][heuristicIndex] = [];
      }
      const currentLinks = newInputs[pageId][release][heuristicIndex];
      if (currentLinks.length === 0 || currentLinks[currentLinks.length - 1]) {
        newInputs[pageId][release][heuristicIndex].push("");
      }
      return newInputs;
    });
  };

  // Handles link input changes
  const handleLinkChange = (
    pageId,
    release,
    heuristicIndex,
    linkIndex,
    value
  ) => {
    setUnsavedChanges(true);
    setModifiedFields((prev) => {
      const newModified = { ...prev };
      if (!newModified[release]) newModified[release] = {};
      if (!newModified[release][pageId]) newModified[release][pageId] = {};
      if (!newModified[release][pageId].links)
        newModified[release][pageId].links = {};
      newModified[release][pageId].links[heuristicIndex] = true;
      return newModified;
    });
    setLinkInputs((prev) => {
      const newInputs = { ...prev };
      if (!newInputs[pageId]) newInputs[pageId] = {};
      if (!newInputs[pageId][release]) newInputs[pageId][release] = {};
      if (!newInputs[pageId][release][heuristicIndex]) {
        newInputs[pageId][release][heuristicIndex] = [];
      }
      newInputs[pageId][release][heuristicIndex][linkIndex] = value;
      return newInputs;
    });
  };

  // Removes a link
  const handleRemoveLink = async (
    pageId,
    release,
    heuristicIndex,
    linkIndex
  ) => {
    setUnsavedChanges(true);
    setModifiedFields((prev) => {
      const newModified = { ...prev };
      if (!newModified[release]) newModified[release] = {};
      if (!newModified[release][pageId]) newModified[release][pageId] = {};
      if (!newModified[release][pageId].links)
        newModified[release][pageId].links = {};
      newModified[release][pageId].links[heuristicIndex] = true;
      return newModified;
    });
    setLinkInputs((prev) => {
      const newInputs = { ...prev };
      if (newInputs[pageId]?.[release]?.[heuristicIndex]) {
        newInputs[pageId][release][heuristicIndex].splice(linkIndex, 1);
      }
      return newInputs;
    });
  };

  // Calculates parent averages
  const calculateParentAverages = async () => {
    setIsCalculating(true);
    try {
      const batch = writeBatch(db);

      releasesList.forEach((release) => {
        if (formData[release]) {
          const releaseRef = doc(db, "releases", release);
          const updateData = {};

          const parentPages = pages.filter((page) => !page.parentPage);

          parentPages.forEach((parentPage) => {
            const childPages = pages.filter(
              (page) => page.parentPage === parentPage.id
            );

            if (childPages.length === 0) {
              const zeroScores = new Array(heuristics.length).fill(0);

              setFormData((prev) => ({
                ...prev,
                [release]: {
                  ...prev[release],
                  [parentPage.id]: {
                    datasets: [{ data: zeroScores }],
                  },
                },
              }));

              updateData[parentPage.id] = {
                datasets: [
                  {
                    data: zeroScores,
                  },
                ],
              };
            } else {
              const averageScores = new Array(heuristics.length).fill(0);

              childPages.forEach((childPage) => {
                const childScores =
                  formData[release][childPage.id]?.datasets?.[0]?.data;
                if (childScores) {
                  childScores.forEach((score, index) => {
                    const numericScore = parseFloat(score) || 0;
                    averageScores[index] += numericScore;
                  });
                }
              });

              const finalAverages = averageScores.map((total) =>
                (total / childPages.length).toFixed(1)
              );

              setFormData((prev) => ({
                ...prev,
                [release]: {
                  ...prev[release],
                  [parentPage.id]: {
                    datasets: [
                      {
                        data: finalAverages.map(Number),
                      },
                    ],
                  },
                },
              }));

              updateData[parentPage.id] = {
                datasets: [
                  {
                    data: finalAverages.map(Number),
                  },
                ],
              };
            }
          });

          batch.set(releaseRef, updateData, { merge: true });
        }
      });

      await batch.commit();
      alert("Parent page averages calculated and saved successfully!");
      setUnsavedChanges(false);
    } catch (error) {
      console.error("Error calculating averages:", error);
      alert("Error calculating averages");
    } finally {
      setIsCalculating(false);
    }
  };

  return (
    <div className="data-entry-container">
      <div className="data-entry-header">
        <div className="release-selector">
          <div className="release-dropdown">
            <div
              className="dropdown-header"
              onClick={() => setIsReleaseFlyoutOpen(!isReleaseFlyoutOpen)}
            >
              <span>
                {selectedRelease
                  ? `Release ${selectedRelease}`
                  : "Select Release"}
              </span>
              <FontAwesomeIcon
                icon={isReleaseFlyoutOpen ? faChevronUp : faChevronDown}
              />
            </div>
            {isReleaseFlyoutOpen && (
              <div className="flyout-container">
                <div className="flyout-menu">
                  {releasesList.length > 0 ? (
                    releasesList.map((release) => (
                      <div key={release} className="flyout-menu-item">
                        <span
                          onClick={() => {
                            setSelectedRelease(release);
                            setIsReleaseFlyoutOpen(false);
                          }}
                        >
                          Release {release}
                        </span>
                        <FontAwesomeIcon
                          icon={faTrash}
                          className="delete-release-icon"
                          onClick={() => handleDeleteRelease(release)}
                        />
                      </div>
                    ))
                  ) : (
                    <div>No Releases Available</div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="page-selector">
          <div className="page-dropdown">
            <div
              className="dropdown-header"
              onClick={() => setIsPageFlyoutOpen(!isPageFlyoutOpen)}
            >
              <span>
                {pages.find((p) => p.id === selectedPage)?.title ||
                  "Select Page"}
              </span>
              <FontAwesomeIcon
                icon={isPageFlyoutOpen ? faChevronUp : faChevronDown}
              />
            </div>
            {isPageFlyoutOpen && (
              <div className="flyout-container">
                <div className="flyout-menu">
                  {pages.length > 0 ? (
                    renderNestedPages(organizePages(pages))
                  ) : (
                    <div>No Pages Available</div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}
        className="data-entry-form"
      >
        {selectedRelease && selectedPage ? (
          <div className="form-section">
            <h3>
              Release {selectedRelease} -{" "}
              {pages.find((p) => p.id === selectedPage)?.title}
            </h3>
            {heuristics.map((heuristic, hIndex) => (
              <div key={hIndex} className="form-group">
                <label
                  htmlFor={`heuristic-${selectedRelease}-${selectedPage}-${hIndex}`}
                >
                  {heuristic.label}:
                </label>
                <div className="input-group">
                  <input
                    type="number"
                    id={`heuristic-${selectedRelease}-${selectedPage}-${hIndex}`}
                    min="0"
                    max="5"
                    step="0.1"
                    value={
                      formData[selectedRelease]?.[selectedPage]?.datasets?.[0]
                        ?.data?.[hIndex] ?? 0
                    }
                    onChange={(e) =>
                      handleInputChange(
                        selectedRelease,
                        selectedPage,
                        hIndex,
                        e.target.value
                      )
                    }
                    required
                  />
                  <textarea
                    placeholder="Add notes..."
                    value={
                      notes[`${selectedRelease}-${selectedPage}-${hIndex}`] ||
                      ""
                    }
                    onChange={(e) =>
                      handleInputChange(
                        selectedRelease,
                        selectedPage,
                        hIndex,
                        e.target.value,
                        true
                      )
                    }
                    className="heuristic-notes"
                  />
                </div>
                <div
                  className={`links-container ${
                    (
                      linkInputs[selectedPage]?.[selectedRelease]?.[hIndex] ||
                      []
                    ).length === 0
                      ? "empty"
                      : "with-links"
                  }`}
                >
                  {(linkInputs[selectedPage]?.[selectedRelease]?.[hIndex] || [])
                    .length === 0 ? (
                    <button
                      type="button"
                      className="initial-add-link"
                      onClick={() =>
                        handleAddLink(selectedPage, selectedRelease, hIndex)
                      }
                    >
                      <FontAwesomeIcon icon={faPlus} />
                      Add Link
                    </button>
                  ) : (
                    linkInputs[selectedPage][selectedRelease][hIndex].map(
                      (link, linkIndex) => (
                        <div key={linkIndex} className="link-input-row">
                          <input
                            type="url"
                            value={link || ""}
                            onChange={(e) =>
                              handleLinkChange(
                                selectedPage,
                                selectedRelease,
                                hIndex,
                                linkIndex,
                                e.target.value
                              )
                            }
                            placeholder="Enter URL"
                          />
                          <div className="link-actions">
                            <button
                              type="button"
                              className="icon-btn"
                              onClick={() =>
                                handleAddLink(
                                  selectedPage,
                                  selectedRelease,
                                  hIndex
                                )
                              }
                            >
                              <FontAwesomeIcon icon={faPlus} />
                            </button>
                            <button
                              type="button"
                              className="icon-btn"
                              onClick={() =>
                                handleRemoveLink(
                                  selectedPage,
                                  selectedRelease,
                                  hIndex,
                                  linkIndex
                                )
                              }
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </div>
                        </div>
                      )
                    )
                  )}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>Please select a release and page to enter data.</p>
        )}

        <div className="settings-container">
          <button
            type="button"
            className="settings-icon"
            onClick={() => setIsSettingsOpen(!isSettingsOpen)}
          >
            <FontAwesomeIcon icon={faCog} />
          </button>

          {isSettingsOpen && (
            <div className="flyout-container">
              <div className="settings-flyout">
                <button
                  type="button"
                  className="generate-data-btn"
                  onClick={generateRandomData}
                >
                  Generate Random Data
                </button>
                <button
                  type="button"
                  className="generate-data-btn"
                  onClick={generateRandomDataForAllReleases}
                >
                  Generate Random Data (All Releases)
                </button>
                <button
                  type="button"
                  onClick={generateRandomDataForEverything}
                  className="generate-data-btn"
                >
                  Generate Random Data for Everything
                </button>
              </div>
            </div>
          )}
        </div>

        <div className="form-actions">
          <button type="submit" className="submit-button">
            Update Data
          </button>
          <button
            type="button"
            className="calculate-btn"
            onClick={calculateParentAverages}
            disabled={isCalculating}
          >
            {isCalculating ? (
              "Calculating..."
            ) : (
              <>
                <FontAwesomeIcon icon={faCalculator} />
                Calculate Parent Averages
              </>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default DataEntryForm;
