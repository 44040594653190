export const calculateAverage = (dataset) => {
  if (!dataset || !dataset.data || dataset.data.length === 0) return 0;

  // dataset = 1.4;
  // console.log(dataset.length);

  const sum = dataset.data.reduce(
    (acc, val) => acc + (parseFloat(val) || 0),
    0
  );

  return (sum / dataset.data.length).toFixed(2);
};
