// DashboardContent.js

import React, { useState, useCallback, useEffect } from "react";
import { useLocation, useSearchParams, Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import PageRoutes from "./PageRoutes";
import BottomControls from "./BottomControls";
import { AuthContext } from "./AuthContext";
import "./DashboardContent.css";
import ToggleSwitch from "./ToggleSwitch";
import ThirdPane from "./ThirdPane";
import { getScoreColor } from "./utils/ScoreColor";
import { calculateAverage } from "./utils/calculateAverage";

const DashboardContent = ({
  sidebarOpen,
  setSidebarOpen,
  isPlaying,
  setIsPlaying,
  selectedPeriod,
  setSelectedPeriod,
  heuristicData,
  chartSize,
  setChartSize,
  pages,
  handleDataUpdate,
  loading,
  error,
  logout,
  userInfo,
  pageTitle,
  previousPeriod,
  isComparePage,
  isNonChartPage,
  setPreviousPeriod,
}) => {
  const location = useLocation();
  const { currentUser } = React.useContext(AuthContext);
  const [selectedDataPoint, setSelectedDataPoint] = useState(null);
  const [thirdPaneOpen, setThirdPaneOpen] = useState(false);
  const [heuristicDataPC, setHeuristicDataPC] = useState(null);
  const [currentView, setCurrentView] = useState("overview");
  const [isChartSizerNeeded, setIsChartSizerNeeded] = useState(false);

  // let isChartSizerNeeded =
  //   new URLSearchParams(location.search).get("view") === "overview";

  const isHomePage = location.pathname === "/home";
  const pageId = isHomePage ? "home" : location.pathname.split("/").pop();

  const specialPages = ["/data-entry", "/add-page", "/add-release"];
  const isSpecialPage = specialPages.includes(location.pathname);

  const updateSelectedDataPoint = useCallback(
    (newPeriod) => {
      if (selectedDataPoint && heuristicData[newPeriod]) {
        const currentPeriodData = heuristicData[newPeriod];



        const pageId = selectedDataPoint.id;
        const pageData = currentPeriodData[pageId];
        const heuristicLabel = selectedDataPoint.label;

        // Check if this is a TreeMap view by checking if label matches pageLabel
        const isTreeMapView =
          selectedDataPoint.label === selectedDataPoint.pageLabel;

        if (isTreeMapView && pageData) {
          // Handle TreeMap view with datasets structure
          if (pageData.datasets && pageData.datasets[0]) {
            const datasetValues = pageData.datasets[0].data;
            const score = calculateAverage(datasetValues);
            const previousPageData =
              previousPeriod !== null
                ? heuristicData[previousPeriod]?.[pageId]?.datasets?.[0]
                : null;
            const previousAverageScore = previousPageData
              ? calculateAverage(previousPageData)
              : 0;

            const heuristicIndex =
              currentPeriodData[pageId].labels.indexOf(heuristicLabel);

            const updatedValue = calculateAverage(
              currentPeriodData[pageId].datasets[0]
            );

            console.log(pageData);
            const updatedDataPoint = {
              ...selectedDataPoint,
              value: parseFloat(updatedValue),
              color: getScoreColor(updatedValue),
              notes: pageData.notes,
              links: pageData.links,
              index: newPeriod,
              previousAverageScore: parseFloat(previousAverageScore),
            };

            setSelectedDataPoint(updatedDataPoint);
          }
        } else {
          // Handle other views (existing logic)
          if (pageData && pageData.datasets?.[0]?.data && pageData.labels) {
            const heuristicIndex = pageData.labels.indexOf(
              selectedDataPoint.label
            );
            if (heuristicIndex !== -1) {
              const updatedValue = pageData.datasets[0].data[heuristicIndex];
              console.log(pageData);
              // const dataset = pageData?.datasets?.find((d) => d.id === page.id);
              if (updatedValue !== undefined) {
                const updatedDataPoint = {
                  ...selectedDataPoint,
                  value: updatedValue,
                  notes: pageData.notes,
                  links: pageData.links,
                  color: getScoreColor(updatedValue),
                };
                setSelectedDataPoint(updatedDataPoint);
              }
            }
          }
        }
      }
    },
    [selectedDataPoint, heuristicData, previousPeriod]
  );

  const handlePeriodChange = useCallback(
    (newPeriod) => {
      console.log(
        "New Period",
        newPeriod,
        "Previous Period",
        previousPeriod,
        "Selected Period",
        selectedPeriod
      );
      //newPeriod != 0 ? newPeriod - 1 : null
      // setPreviousPeriod(newPeriod != 0 ? selectedPeriod : null); // old
      setPreviousPeriod(newPeriod != 0 ? newPeriod - 1 : null);
      setSelectedPeriod(newPeriod);
      updateSelectedDataPoint(newPeriod);
    },
    [setSelectedPeriod, updateSelectedDataPoint, selectedPeriod]
  );

  const handleDataPointClick = useCallback(
    (dataPoint, heuristicDataPC = null) => {
      setSelectedDataPoint(dataPoint);
      setHeuristicDataPC(heuristicDataPC);
      setThirdPaneOpen(true);
    },
    []
  );

  const closeThirdPane = useCallback(() => {
    setThirdPaneOpen(false);
    setSelectedDataPoint(null);
  }, []);

  const getPageData = useCallback(
    (pageId) => {
      if (isSpecialPage) return {}; // Return an empty object for special pages
      if (isComparePage) return heuristicData; // Return all data for compare page
      if (!heuristicData || heuristicData.length === 0) return null;
      const currentPeriodData = heuristicData[selectedPeriod];
      if (!currentPeriodData) return null;
      return currentPeriodData[pageId] || null;
    },
    [heuristicData, selectedPeriod, isSpecialPage, isComparePage]
  );

  const getAllPagesData = useCallback(() => {
    if (!heuristicData || heuristicData.length === 0) return null;
    const currentPeriodData = heuristicData[selectedPeriod];
    if (!currentPeriodData) return null;

    let combinedData = {
      labels: [],
      datasets: [],
    };

    Object.entries(currentPeriodData).forEach(([pageId, pageData]) => {
      if (pageData && pageData.datasets && pageData.datasets.length > 0) {
        if (combinedData.labels.length === 0) {
          combinedData.labels = pageData.labels || [];
        }
        const page = pages.find((p) => p.id === pageId);
        combinedData.datasets.push({
          ...pageData.datasets[0],
          label: page ? page.title : "Unknown Page",
          id: pageId,
        });
      }
    });

    return combinedData;
  }, [heuristicData, selectedPeriod, pages]);

  const pageData = isHomePage ? getAllPagesData() : getPageData(pageId);

  const updateView = useCallback((newView) => {
    setCurrentView(newView);
    setIsChartSizerNeeded(newView === "overview");
  }, []);

  // Close ThirdPane when URL changes
  useEffect(() => {
    if (thirdPaneOpen) {
      setThirdPaneOpen(false);
      setSelectedDataPoint(null);
    }
  }, [location.pathname, location.search]);

  return (
    <div
      className={`dashboard ${
        sidebarOpen ? "sidebar-open" : "sidebar-closed"
      } ${thirdPaneOpen ? "third-pane-open" : ""} ${pageTitle}`}
    >
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        pages={pages}
        logout={logout}
        userInfo={userInfo}
        currentUser={currentUser}
        closeThirdPane={closeThirdPane}
      />
      <div className="main-content">
        {loading && <div>Loading data...</div>}
        {error && <div className="error-message">{error}</div>}
        {!loading && !error && (
          <>
            {!isSpecialPage && !isComparePage && !isNonChartPage && (
              <ToggleSwitch
                currentPage={pageTitle}
                currentView={currentView}
                updateView={updateView}
              />
            )}
            <PageRoutes
              pages={pages}
              heuristicData={heuristicData}
              selectedPeriod={selectedPeriod}
              previousPeriod={previousPeriod}
              chartSize={chartSize}
              handleDataUpdate={handleDataUpdate}
              pageTitle={pageTitle}
              onDataPointClick={handleDataPointClick}
              thirdPaneOpen={thirdPaneOpen}
              view={currentView}
              updateView={updateView}
              setThirdPaneOpen={setThirdPaneOpen}
              setSelectedDataPoint={setSelectedDataPoint}
              isComparePage={isComparePage}
            />
          </>
        )}
        <BottomControls
          isPlaying={isPlaying}
          setIsPlaying={setIsPlaying}
          selectedPeriod={selectedPeriod}
          setSelectedPeriod={setSelectedPeriod}
          onPeriodChange={handlePeriodChange}
          heuristicData={heuristicData}
          chartSize={chartSize}
          setChartSize={setChartSize}
          isComparePage={isComparePage}
          isNonChartPage={isNonChartPage}
          setSelectedDataPoint={setSelectedDataPoint}
          updateSelectedDataPoint={updateSelectedDataPoint}
          setPreviousPeriod={setPreviousPeriod}
          isChartSizerNeeded={isChartSizerNeeded}
        />
      </div>
      {thirdPaneOpen && selectedDataPoint && (
        <ThirdPane
          selectedDataPoint={selectedDataPoint}
          heuristicData={heuristicDataPC || heuristicData}
          selectedPeriod={selectedPeriod}
          closeThirdPane={closeThirdPane}
          previousPeriod={previousPeriod}
          isHomePage={isHomePage}
          pages={pages}
        />
      )}
    </div>
  );
};

export default DashboardContent;
