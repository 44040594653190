import React from 'react';
import { Link } from 'react-router-dom';
import './CallToActionSection.css';

const CallToActionSection = () => (
  <section className="cta">
    <div className="container">
      <h2>Ready to Elevate Your Workflow?</h2>
      <p>Join thousands of professionals using YourAppName to streamline their projects.</p>
      <Link to="/signup" className="cta-button">Create Your Account</Link>
    </div>
  </section>
);

export default CallToActionSection;
