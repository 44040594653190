import React from "react";
import "./AboutSection.css";

const AboutSection = () => (
  <section className="about">
    <div className="container about-content">
      <div className="about-image">
        <img
          src="https://images.ctfassets.net/ukazlt65o6hl/44runY8GJsCDQGt0ud4UaT/669b326a8cf6e040c760271e00799158/Privacy_-_01.png?w=1485&h=1089&q=70&fm=png"
          alt="Usablity"
        />
      </div>
      <div className="about-text">
        <h2>Why Choose YourAppName?</h2>
        <p>
          YourAppName simplifies project management, allowing you to focus on
          what really matters—achieving your goals.
        </p>
        <ul>
          <li>Intuitive interface that's easy to navigate</li>
          <li>Robust features to handle complex projects</li>
          <li>Dedicated support to assist you every step of the way</li>
        </ul>
      </div>
    </div>
  </section>
);

export default AboutSection;
