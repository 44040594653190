import React, { useState, useEffect } from "react";
import { db, auth } from "./firebase";
import { collection, addDoc, Timestamp, getDocs } from "firebase/firestore";
import { useNavigate, useLocation } from "react-router-dom";
import { HexColorPicker, HexColorInput } from "react-colorful";

import "./AddPage.css";

const AddPage = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [color, setColor] = useState("#000000");
  const [parentPage, setParentPage] = useState("");
  const [pages, setPages] = useState([]);
  const [swatches, setSwatches] = useState(
    JSON.parse(localStorage.getItem("colorSwatches")) || []
  );

  const navigate = useNavigate();
  const location = useLocation();

  // Fetch pages for selecting a parent page
  useEffect(() => {
    const fetchPages = async () => {
      try {
        const pagesCollection = collection(db, "pages");
        const pagesSnapshot = await getDocs(pagesCollection);
        const pagesData = pagesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPages(pagesData);
      } catch (error) {
        console.error("Error fetching pages:", error);
      }
    };

    fetchPages();
  }, []);

  // Set parent page if a parent ID is provided in the query string
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const parentId = params.get("parent");
    if (parentId) {
      setParentPage(parentId);
    }
  }, [location.search]);

  // Save swatches to localStorage when updated
  useEffect(() => {
    localStorage.setItem("colorSwatches", JSON.stringify(swatches));
  }, [swatches]);

  const handleAddPage = async (e) => {
    e.preventDefault();

    if (!auth.currentUser) {
      alert("You must be logged in to add a page.");
      return;
    }

    // Basic form validation
    if (title.trim().length < 3) {
      setErrorMessage("Title must be at least 3 characters long.");
      return;
    }

    if (content.trim().length < 10) {
      setErrorMessage("Content must be at least 10 characters long.");
      return;
    }

    setLoading(true);
    setErrorMessage("");

    try {
      const newPage = {
        title: title.trim(),
        content: content.trim(),
        color: color,
        parentPage: parentPage || null,
        createdBy: auth.currentUser.uid,
        createdAt: Timestamp.now(),
      };
      const docRef = await addDoc(collection(db, "pages"), newPage);
      setLoading(false);
      navigate(`/page/${docRef.id}`);
    } catch (error) {
      console.error("Error adding page:", error);
      setErrorMessage("Failed to create page. Please try again.");
      setLoading(false);
    }
  };

  const saveNewSwatch = (colorToSave) => {
    const updatedSwatches = [...swatches, colorToSave];
    setSwatches(updatedSwatches);
    localStorage.setItem("colorSwatches", JSON.stringify(updatedSwatches));
  };

  return (
    <div className="add-page-container" role="main">
      <div className="add-page-header">
        <h3>Add New Page</h3>
      </div>

      <form onSubmit={handleAddPage} className="add-page-form">
        <div className="form-group">
          <label htmlFor="title">Title:</label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            disabled={loading}
          />
        </div>

        <div className="form-group">
          <label htmlFor="parentPage">Parent Page:</label>
          <select
            id="parentPage"
            value={parentPage}
            onChange={(e) => setParentPage(e.target.value)}
            disabled={loading}
          >
            <option value="">None (Root Page)</option>
            {pages.map((page) => (
              <option key={page.id} value={page.id}>
                {page.title}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="color">Page Color:</label>
          <div className="color-picker-container">
            <HexColorPicker color={color} onChange={setColor} />
            <div className="color-input-row">
              <span>#</span>
              <HexColorInput
                prefixed={false}
                color={color}
                onChange={setColor}
                disabled={loading}
              />
              <button
                type="button"
                className="save-swatch-button"
                onClick={() => saveNewSwatch(color)}
                disabled={loading || swatches.includes(color)}
              >
                Save Swatch
              </button>
            </div>
            <div className="swatches">
              {swatches.map((swatch) => (
                <button
                  key={swatch}
                  className="swatch"
                  style={{ background: swatch }}
                  onClick={() => setColor(swatch)}
                >
                  <span className="swatch-tooltip">{swatch}</span>
                </button>
              ))}
            </div>
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="content">Content:</label>
          <textarea
            id="content"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            disabled={loading}
            rows={4}
          />
        </div>

        {errorMessage && <div className="error-message">{errorMessage}</div>}

        <div className="form-actions">
          <button type="submit" className="submit-button" disabled={loading}>
            {loading ? "Adding..." : "Add Page"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddPage;
