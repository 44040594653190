import React from 'react';
import Navbar from './Navbar';
import HeroSection from './HeroSection';
import FeaturesSection from './FeaturesSection';
import AboutSection from './AboutSection';
import TestimonialsSection from './TestimonialsSection';
import CallToActionSection from './CallToActionSection';
import Footer from './Footer';

const HomePage = () => (
  <>
    <Navbar />
    <main>
      <HeroSection />
      <FeaturesSection />
      <AboutSection />
      <TestimonialsSection />
      <CallToActionSection />
    </main>
    <Footer />
  </>
);

export default HomePage;
