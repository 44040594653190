// utils/colorUtils.js

export const getScoreColor = (score) => {
  if (!isNaN(score)) {
    if (score >= 4.5 && score < 5.5) return "#82E486";
    if (score >= 3.5 && score < 4.5) return "#74D9C3";
    if (score >= 2.5 && score < 3.5) return "#F3BF23";
    if (score >= 1.5 && score < 2.5) return "#FFA371";
    if (score >= 0 && score < 1.5) return "#BF0455";
  }
  return "#FFFFFF"; // Set a default color to prevent transparency
};
