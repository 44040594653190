// authProviders.js

import {
    GoogleAuthProvider,
    FacebookAuthProvider,
    TwitterAuthProvider,
    OAuthProvider,
  } from "firebase/auth";
  
  // Google provider
  const googleProvider = new GoogleAuthProvider();
  
  // Facebook provider
  const facebookProvider = new FacebookAuthProvider();
  
  // Twitter provider
  const twitterProvider = new TwitterAuthProvider();
  
  // Microsoft provider
  const microsoftProvider = new OAuthProvider("microsoft.com");
  
  // Optional: Set custom parameters for providers if needed
  // e.g., facebookProvider.setCustomParameters({ display: 'popup' });
  
  export {
    googleProvider,
    facebookProvider,
    twitterProvider,
    microsoftProvider,
  };
  