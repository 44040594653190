// PageRoutes.js

import React, { useCallback } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PageView from "./PageView";
import DataEntryForm from "./DataEntryForm";
import AddPage from "./AddPage";
import AddRelease from "./AddRelease";
import Compare from "./Compare";
import Login from "./Login";
import SignUp from "./SignUp";
import { calculateAverage } from "./utils/calculateAverage";
import "./DetailView.css";

const PageRoutes = ({
  pages,
  heuristicData,
  selectedPeriod,
  previousPeriod,
  chartSize,
  handleDataUpdate,
  pageTitle,
  onDataPointClick,
  thirdPaneOpen,
  view,
  setThirdPaneOpen,
  setSelectedDataPoint,
  isComparePage,
}) => {
  const location = useLocation();

  const getPageData = useCallback(
    (pageId) => {
      if (
        !heuristicData ||
        !Array.isArray(heuristicData) ||
        !heuristicData[selectedPeriod]
      ) {
        return null;
      }

      const currentPeriodData = heuristicData[selectedPeriod];
      const pageData = currentPeriodData[pageId];

      if (!pageData || !pageData.datasets) {
        return null;
      }

      const page = pages.find((p) => p.id === pageId);
      if (!page) return null;

      const previousPeriodData =
        previousPeriod !== null ? heuristicData[previousPeriod] : null;

      return {
        labels: pageData.labels || [],
        datasets: pageData.datasets.map((dataset) => ({
          ...dataset,
          label: page.title,
          notes: pageData.notes || {}, // Pass full notes object
          links: pageData.links || {}, // Pass full links object
          id: pageId,
        })),
        previousData: previousPeriodData,
      };
    },
    [heuristicData, selectedPeriod, previousPeriod, pages]
  );

  const getAllPagesData = useCallback(() => {
    if (
      !heuristicData ||
      !Array.isArray(heuristicData) ||
      !heuristicData[selectedPeriod]
    ) {
      return null;
    }

    const currentPeriodData = heuristicData[selectedPeriod];
    const parentPages = pages.filter((page) => !page.parentPage);

    const datasets = parentPages
      .map((page) => {
        const pageData = currentPeriodData[page.id];
        if (!pageData?.datasets?.[0]) return null;

        return {
          ...pageData.datasets[0],
          label: page.title,
          id: page.id,
          notes: pageData.notes,
          links: pageData.links,
        };
      })
      .filter(Boolean);

    if (datasets.length === 0) return null;

    const firstPageId = parentPages[0].id;
    const labels = currentPeriodData[firstPageId]?.labels || [];

    const previousPeriodData =
      previousPeriod !== null && heuristicData[previousPeriod]
        ? heuristicData[previousPeriod]
        : null;

    return {
      labels,
      datasets,
      previousData: previousPeriodData,
    };
  }, [heuristicData, selectedPeriod, previousPeriod, pages]);

  const prepareDetailViewData = (selectedPageId) => {
    const currentRelease = heuristicData[selectedPeriod];

    let filteredPages = [];
    let heuristics = [];
    let heuristicDataForPages = {};

    if (!selectedPageId || selectedPageId.toLowerCase() === "home") {
      const parentPages = pages.filter((page) => !page.parentPage);
      filteredPages = parentPages.map((page) => ({
        id: page.id,
        title: page.title,
      }));
    } else {
      const selectedPage = pages.find((page) => page.id === selectedPageId);
      if (selectedPage) {
        const childPages = pages.filter(
          (page) => page.parentPage === selectedPageId
        );
        filteredPages = [
          { id: selectedPage.id, title: selectedPage.title },
          ...childPages,
        ];
      }
    }

    if (currentRelease && filteredPages.length > 0) {
      const firstPageId = filteredPages[0].id;
      const firstPageData = currentRelease[firstPageId];

      // Get heuristics from labels if available
      if (firstPageData?.labels) {
        heuristics = firstPageData.labels;
      } else if (firstPageData) {
        // Fallback to object keys if it's already in the correct format
        heuristics = Object.keys(firstPageData);
      }

      // Transform data for each page
      filteredPages.forEach((page) => {
        let pageData = currentRelease[page.id];

        if (pageData) {
          if (pageData.datasets && pageData.labels) {
            // Transform from datasets format to key-value format
            const transformedData = {};
            pageData.labels.forEach((label, index) => {
              transformedData[label] = [
                pageData.datasets[0].data[index],
                pageData.notes ? pageData.notes[index] : "No notes, yet. :)",
                pageData.links ? pageData.links[index] : "",
              ];
            });
            // console.log(transformedData);
            heuristicDataForPages[page.id] = transformedData;
          } else {
            // Data is already in the correct format
            heuristicDataForPages[page.id] = pageData;
          }
        } else {
          heuristicDataForPages[page.id] = {};
        }
      });
    }

    return {
      filteredPages,
      heuristics,
      heuristicDataForPages,
    };
  };

  return (
    <Routes>
      {pages.map((page) => (
        <Route
          key={page.id}
          path={`/page/${page.id}`}
          element={
            <PrivateRoute>
              <PageView
                pageData={getPageData(page.id)}
                chartSize={chartSize}
                pageId={page.id}
                title={page.title}
                isHomePage={false}
                onDataPointClick={onDataPointClick}
                thirdPaneOpen={thirdPaneOpen}
                view={view}
                pages={prepareDetailViewData(page.id).filteredPages}
                heuristics={prepareDetailViewData(page.id).heuristics}
                heuristicData={
                  prepareDetailViewData(page.id).heuristicDataForPages
                }
                heuristicDataPC={heuristicData}
                selectedPeriod={selectedPeriod}
                previousPeriod={previousPeriod}
                setSelectedDataPoint={setSelectedDataPoint}
                setThirdPaneOpen={setThirdPaneOpen}
                previousData={heuristicData[previousPeriod]}
              />
            </PrivateRoute>
          }
        />
      ))}
      <Route
        path="/home"
        element={
          <PrivateRoute>
            <PageView
              pageData={getAllPagesData()}
              chartSize={chartSize}
              title="Overview"
              isHomePage={true}
              onDataPointClick={onDataPointClick}
              thirdPaneOpen={thirdPaneOpen}
              view={view}
              pages={prepareDetailViewData("home").filteredPages}
              heuristics={prepareDetailViewData("home").heuristics}
              heuristicData={
                prepareDetailViewData("home").heuristicDataForPages
              }
              selectedPeriod={selectedPeriod}
              previousPeriod={previousPeriod}
              setSelectedDataPoint={setSelectedDataPoint}
              setThirdPaneOpen={setThirdPaneOpen}
              previousData={heuristicData[previousPeriod]}
              heuristicDataPC={heuristicData}
            />
          </PrivateRoute>
        }
      />
      <Route
        path="/data-entry"
        element={
          <PrivateRoute>
            <DataEntryForm onDataUpdate={handleDataUpdate} />
          </PrivateRoute>
        }
      />
      <Route
        path="/add-page"
        element={
          <PrivateRoute>
            <AddPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/add-release"
        element={
          <PrivateRoute>
            <AddRelease />
          </PrivateRoute>
        }
      />
      <Route
        path="/compare"
        element={
          <PrivateRoute>
            <Compare
              heuristicData={getAllPagesData()}
              onDataPointClick={onDataPointClick}
              thirdPaneOpen={thirdPaneOpen}
            />
          </PrivateRoute>
        }
      />
      <Route path="/" element={<Login />} />
      <Route path="/signup" element={<SignUp />} />
    </Routes>
  );
};

export default PageRoutes;
