// App.js

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "./AuthContext";
import PrivateRoute from "./PrivateRoute";
import Auth from "./Auth";
import Login from "./Login";
import SignUp from "./SignUp";
import Dashboard from "./Dashboard";
import HomePage from "./HomePage";

function App() {
  const { authIsReady } = useContext(AuthContext);
  if (!authIsReady) {
    // You can return a loading indicator here
    return <div>Loading...</div>;
  }
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/auth" element={<Auth />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route
            path="/*"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />

          <Route path="/" element={<HomePage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
