// HeroSection.js
import React from "react";
import { Link } from "react-router-dom";
import "./HeroSection.css";

const HeroSection = () => (
  <section className="hero">
    <div className="container hero-content">
      <div className="hero-text">
        <h1>Our Mission</h1>
        <p>
          We are committed to delivering exceptional user experiences by
          addressing usability to help our customers, brands and users grow in
          Sprinklr through measurable platform usability scoring.
        </p>
        <Link to="/learn" className="cta-button">
          Get involved!
        </Link>
      </div>
    </div>
  </section>
);

export default HeroSection;
