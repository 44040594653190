import React from "react";

import PercentChange from "./utils/PercentChange";
import calculatePreviousAverageScores from "./utils/calculatePreviousAverageScores";

const ScoreWithPercentChange = React.memo(
  ({
    score,
    label,
    data,
    previousData,
    index,
    isHomePage,
    showScore = true,
    pageId,
    scorePage,
  }) => {
    const previousScore =
      previousData && pageId
        ? previousData[pageId]?.datasets[0]?.data[index]
        : [];

    //scorePage is true when you are wanting percent change for avg score and not individual heuristic scores
    const previousAverageScores = scorePage
      ? calculatePreviousAverageScores(data, previousData, isHomePage)
      : previousScore;

    return (
      <div className={`score-container ${label}`}>
        {showScore && <span className="scoreValue">{score}</span>}
        <PercentChange
          currentValue={score}
          datasetIndex={index}
          previousAverageScores={previousAverageScores}
        />
      </div>
    );
  },
  (prevProps, nextProps) => {
    // Only re-render if these props actually changed to prevent component data collisions
    return (
      prevProps.score === nextProps.score &&
      prevProps.label === nextProps.label &&
      prevProps.index === nextProps.index
    );
  }
);

export default ScoreWithPercentChange;
