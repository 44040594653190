import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth"; // Import auth module

const firebaseConfig = {
  apiKey: "AIzaSyCu4DYcF30bG86DseOTXbGTToIMGszg8UI",
  authDomain: "sprinklr-use-framework.firebaseapp.com",
  projectId: "sprinklr-use-framework",
  storageBucket: "sprinklr-use-framework.appspot.com",
  messagingSenderId: "728416676069",
  appId: "1:728416676069:web:c7da7a2f527fa845d3cc0c",
};

const SANDBOX_firebaseConfig = {
  apiKey: "AIzaSyC81HrkaVqbOmBlne3hN24ol8HGzcf3-cY",
  authDomain: "uselyapp-sandbox.firebaseapp.com",
  projectId: "uselyapp-sandbox",
  storageBucket: "uselyapp-sandbox.firebasestorage.app",
  messagingSenderId: "844462365569",
  appId: "1:844462365569:web:2dbfa383753a5acf2e6680",
};

const localhost = window.location.hostname === "localhost";

const isLocalhost = (host) => {
  console.log("isLocalhost", host);
  if (isLocalhost) {
    return SANDBOX_firebaseConfig;
  } else {
    return firebaseConfig;
  }
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const auth = getAuth(app); // Initialize Firebase Authentication

export { db, auth }; // Export both db and auth
