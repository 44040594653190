import React, { useCallback } from "react";
import "./ThirdPane.css";
import ScoreWithPercentChange from "./ScoreWithPercentChange";
import { faFlag, faNoteSticky } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ThirdPane = ({
  selectedDataPoint,
  heuristicData,
  selectedPeriod,
  closeThirdPane,
  previousPeriod,
  isHomePage,
  pages,
}) => {
  const getPageData = useCallback(
    (pageId) => {
      if (
        !heuristicData ||
        !Array.isArray(heuristicData) ||
        !heuristicData[selectedPeriod]
      ) {
        return null;
      }

      const currentPeriodData = heuristicData[selectedPeriod];
      const pageData = currentPeriodData[pageId];

      if (!pageData || !pageData.datasets) {
        return null;
      }

      const page = pages.find((p) => p.id === pageId);
      if (!page) return null;

      const previousPeriodData =
        previousPeriod !== null ? heuristicData[previousPeriod] : null;

      return {
        labels: pageData.labels || [],
        datasets: pageData.datasets.map((dataset) => ({
          ...dataset,
          label: page.title,
          id: pageId,
        })),
        previousData: previousPeriodData,
      };
    },
    [heuristicData, selectedPeriod, previousPeriod, pages]
  );

  if (!selectedDataPoint) return null;

  const currentPeriodData = heuristicData?.[selectedPeriod];
  const isTreeMapView = selectedDataPoint.label === selectedDataPoint.pageLabel;

  console.log("Selected Data Point ---> ", selectedDataPoint);

  return (
    <div className="third-pane">
      <button className="close-third-pane" onClick={closeThirdPane}>
        &times;
      </button>
      <div className="average-score">
        <div className="label-container">
          <div
            className="color-square"
            style={{ backgroundColor: selectedDataPoint.color }}
          ></div>
          <label>
            <span className="release-number">
              {currentPeriodData?.release ?? "N/A"}
            </span>
            <span className="relaese-name">{selectedDataPoint.pageLabel}</span>
          </label>
        </div>
      </div>
      <div
        className="heuristic"
        style={{ backgroundColor: selectedDataPoint.color }}
      >
        <h3>
          <FontAwesomeIcon icon={faFlag} /> Heuristic Score
        </h3>
        <ScoreWithPercentChange
          key={`thirdpane-${selectedDataPoint.id}-${selectedDataPoint.index}`}
          score={selectedDataPoint.value.toFixed(2)}
          label={selectedDataPoint.pageLabel}
          data={getPageData(selectedDataPoint.id)}
          previousData={getPageData(selectedDataPoint.id).previousData}
          index={!isTreeMapView ? selectedDataPoint.index : 0}
          isHomePage={isHomePage}
          pageId={selectedDataPoint.id}
          scorePage={selectedDataPoint.scorePage}
          dataPointSelected={selectedDataPoint?.index > -1}
        />
        <label>{selectedDataPoint.label}</label>
        <p>{selectedDataPoint.description}</p>
      </div>

      <div className="notes">
        <h3>
          <FontAwesomeIcon icon={faNoteSticky} /> Notes
        </h3>
        <p>
          {typeof selectedDataPoint.notes === "string"
            ? selectedDataPoint.notes
            : selectedDataPoint.notes[selectedDataPoint.index] ||
              "No notes available"}
        </p>
      </div>
      <div className="links">
        <h3>Links</h3>
        {(typeof selectedDataPoint.links === "string"
          ? [selectedDataPoint.links]
          : selectedDataPoint.links?.[selectedDataPoint.index] || []
        ).length > 0 ? (
          <ul>
            {(Array.isArray(selectedDataPoint.links)
              ? selectedDataPoint.links
              : selectedDataPoint.links[selectedDataPoint.index]
            ).map((link, i) => (
              <li key={i}>
                <a href={link} target="_blank" rel="noopener noreferrer">
                  {link}
                </a>
              </li>
            ))}
          </ul>
        ) : (
          <p>No links available</p>
        )}
      </div>
    </div>
  );
};

export default ThirdPane;
