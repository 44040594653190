import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { calculateAverage } from "./utils/calculateAverage";
import { getScoreColor } from "./utils/ScoreColor";
import ScoreWithPercentChange from "./ScoreWithPercentChange";
import PercentChange from "./utils/PercentChange";
import "./ScoreTableView.css";

const ScoreTableView = ({
  pages,
  heuristicData,
  previousData,
  selectedPeriod,
  previousPeriod,
  data,
  title,
  isHomePage,
}) => {
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "ascending" ? faChevronUp : faChevronDown;
    }
    return faChevronUp; // Default icon (can be adjusted)
  };

  const formatValue = (value) => {
    const numValue = parseFloat(value);
    return !isNaN(numValue) ? numValue.toFixed(2) : "-";
  };

  // Prepare scores data
  const scoreData = pages.map((page) => {
    const pageData = heuristicData[page.id];
    let averageScore = 0;

    // Calculate average score based on the data structure
    if (pageData) {
      if (Array.isArray(pageData)) {
        averageScore = calculateAverage({ data: pageData });
      } else if (typeof pageData === "object") {
        averageScore = calculateAverage({ data: Object.values(pageData) });
      }
    }

    // Calculate previous average score
    let previousAverageScore = 0;
    if (previousData && previousData[page.id]) {
      const prevData = previousData[page.id];
      if (prevData.datasets && prevData.datasets[0]?.data) {
        previousAverageScore = calculateAverage({
          data: prevData.datasets[0].data,
        });
      } else if (typeof prevData === "object") {
        previousAverageScore = calculateAverage({
          data: Object.values(prevData),
        });
      }
    }

    // Determine grade
    let scoreGrade = "F";
    if (averageScore >= 4.5) scoreGrade = "A";
    else if (averageScore >= 3.5) scoreGrade = "B";
    else if (averageScore >= 2.5) scoreGrade = "C";
    else if (averageScore >= 1.5) scoreGrade = "D";

    return {
      id: page.id,
      title: page.title,
      averageScore,
      previousAverageScore,
      scoreGrade,
      color: getScoreColor(averageScore),
    };
  });

  const sortedScoreData = React.useMemo(() => {
    const sortableItems = [...scoreData];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [scoreData, sortConfig]);

  return (
    <div className="heuristic-table-container scoreTable">
      <table className="heuristic-table">
        <thead>
          <tr>
            <th
              onClick={() => requestSort("title")}
              className={`sortable ${
                sortConfig.key === "title" ? "sorted" : ""
              }`}
            >
              Product Area
              <FontAwesomeIcon
                icon={getSortIcon("title")}
                className="sort-icon"
              />
            </th>
            <th
              onClick={() => requestSort("scoreGrade")}
              className={`sortable ${
                sortConfig.key === "scoreGrade" ? "sorted" : ""
              }`}
            >
              Grade
              <FontAwesomeIcon
                icon={getSortIcon("scoreGrade")}
                className="sort-icon"
              />
            </th>
            <th
              onClick={() => requestSort("averageScore")}
              className={`sortable ${
                sortConfig.key === "averageScore" ? "sorted" : ""
              }`}
            >
              Average Score
              <FontAwesomeIcon
                icon={getSortIcon("averageScore")}
                className="sort-icon"
              />
            </th>
            <th>Percent Change</th>
          </tr>
        </thead>
        <tbody>
          {sortedScoreData.map((score, index) => (
            <tr key={score.id}>
              <td>{score.title}</td>
              <td>{score.scoreGrade}</td>
              <td
                style={{
                  backgroundColor: score.color,
                }}
              >
                {formatValue(score.averageScore)}
              </td>
              <td>
                <ScoreWithPercentChange
                  score={score.averageScore}
                  label={title}
                  data={data}
                  previousData={previousData}
                  index={index}
                  isHomePage={isHomePage}
                  showScore={false}
                  scorePage={true}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ScoreTableView;
