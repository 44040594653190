import React from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

const Navbar = () => (
  <header className="navbar">
    <div className="container">
      <Link to="/" className="logo">
        USE
      </Link>
      <nav className="nav-links">
        <Link to="/features">What is USE?</Link>
        <Link to="/pricing">Conducting USE</Link>
        <Link to="/about">How To use USE</Link>
        <Link to="/contact">FAQ</Link>
      </nav>
      <div className="auth-links">
        <Link to="/auth" className="login-btn">
          Login
        </Link>
        <Link to="/signup" className="signup-btn">
          Sign Up
        </Link>
      </div>
    </div>
  </header>
);

export default Navbar;
